<template>
  <div class="contact">
    <div class="get-in-touch">
      <div class="address-phone">
        <div>
          <span class="icon-map-marker icon"></span>
          <div class="information">
            <h4>Address</h4>
            <p>15, Ikarou Street,</p>
            <p>Shop 1, Kato Pafos,</p>
            <p>8041, Paphos,</p>
            <p>Cyprus</p>
          </div>
          <span class="icon-phone icon"></span>
          <div class="information">
            <h4>Phone</h4>
            <p>(+357) 26 933 080</p>
            <p>(+357) 99 938 843 </p>
            <p>[Whatsapp]</p>
          </div>
        </div>
        <h1 style="margin-top: 40px;">Get In Touch</h1>
        <hr>
        <p style="margin-top: 20px;">If you want to request information, please complete and submit</p>
        <p>the form below. You will be soon contacted by our staff.</p>
        <RequestForm></RequestForm>
      </div>
      <!--      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d623.5457069258849!2d32.41958802131151!3d34.7595344703933!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14e707994237986f%3A0xcfeb7fe14903d162!2sA.S+Alpan+Car+Rentals+Ltd.!5e1!3m2!1sen!2s!4v1558968671649!5m2!1sen!2s" style="border: 0px none; pointer-events: none;" allowfullscreen="" width="600" height="600" frameborder="0"></iframe>-->
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1032.8603080678731!2d32.419631690134146!3d34.75968173092041!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14e706c1e7da7ea5%3A0x24632ae4dfef3af5!2zSWthcm91IDE1LCBQYXBob3MgODA0MSwg0JrQuNC_0YA!5e1!3m2!1sru!2sua!4v1636532751604!5m2!1sru!2sua"  style="border:0;" allowfullscreen="" loading="lazy"></iframe>
    </div>
  </div>
</template>

<script>
import RequestForm from "../components/RequestForm";
export default {
  name: "Contact",
  components: {
    RequestForm
  }
}
</script>

<style scoped>
  .contact {
    width: 100%;
    padding-top: 130px;
  }
  .get-in-touch {
    padding: 5rem 15% 20px 15%;
    width: 70%;
    display: flex;
    justify-content: space-between;
    background-color: rgb(249, 249, 249);
  }
  .get-in-touch > * {
    width: 49%;
    max-width: 49%;
  }
  .get-in-touch > div:first-child {
    height: 100%;
  }
  .address-phone {
    display: flex;
    flex-direction: column;
  }
  .address-phone > div:first-child {
    display: flex;
    justify-content: space-around;
    width: 100%;
  }
  .address-phone * {
    font-family: "Raleway", sans-serif;
    font-weight: 300;
    font-size: 1rem;
  }
  .address-phone p {
    margin: 2px 0;
    font-size: 0.9rem;
    text-align: left;
    color: rgba(0,0,0,0.9);
  }
  .address-phone h4, .address-phone h1 {
    font-weight: 400;
    text-align: left;
  }
  .address-phone h1 {
    font-size: 2rem;
  }
  .address-phone hr {
    float: left;
    width: 20%;
    margin: 0;
  }
  .address-phone .icon {
    width: 4rem;
    height: 4rem;
    background-color: orange;
    border-radius: 50%;
    position: relative;
    margin: auto;
  }
  .address-phone .icon::before {
    color: #ffffff;
    width: 2rem;
    height: 2rem;
    font-size: 2rem;
    position: absolute;
    top: calc(50% - 1rem);
    left: calc(50% - 1rem);
  }
  .information {
    display: flex;
    flex-direction: column;
  }
  @media screen and (max-width: 767px){
    .get-in-touch {
      flex-direction: column;
      padding: 5rem 2.5% 20px 2.5%;
      width: 95%;
    }
    .get-in-touch > * {
      width: 100%;
      max-width: 100%;
    }
    .get-in-touch iframe {
      min-height: 30rem;
      margin-top: 20px;
    }
    .address-phone .icon {
      width: 3rem;
      height: 3rem;
    }
    .address-phone p {
      font-size: 0.8rem;
      margin-top: 0;
    }
    .address-phone .icon::before {
      /*color: #ffffff;*/
      width: 1.5rem;
      height: 1.5rem;
      font-size: 1.5rem;
      /*position: absolute;*/
      top: calc(50% - 0.75rem);
      left: calc(50% - 0.75rem);
    }
  }
</style>